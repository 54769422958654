<template>
  <div class="row" id="email-smtp-config">
    <div class="col-md-6 offset-3">
      <div class="card form-body">
        <div class="card-body">
          <legend v-if="currentUser.c.payment_method == 'heartland'">
            <h5>Payment Method - Heartland</h5>
          </legend>
          <legend v-else>
            <h5>Payment Method - CardPointe</h5>
          </legend>
          <hr />
          <form v-if="currentUser.c.payment_method != 'heartland'">
            <div class="form-group">
              <label for="">Username <span class="text-danger">*</span></label>
              <input
                type="email"
                name=""
                class="form-control"
                id=""
                v-model="username"
                :class="errors['username'] ? 'border border-danger' : ''"
              />
              <span v-if="errors['username']" class="text-danger">{{
                errors["username"][0]
              }}</span>
            </div>

            <div class="form-group mb-1">
              <label for="">Password <span class="text-danger">*</span></label>
              <div class="input-group">
                <input
                  :type="password_type"
                  name=""
                  class="form-control"
                  id=""
                  v-model="password"
                  :class="errors['password'] ? 'border border-danger' : ''"
                />
                <div
                  class="input-group-append"
                  style="cursor: pointer"
                  @click="showPassword()"
                >
                  <span class="input-group-text">
                    <!-- <i class="fa fa-eye"></i> -->
                    <i
                      :class="
                        password_type == 'password'
                          ? 'fa fa-eye'
                          : 'fa fa-eye-slash'
                      "
                    ></i>
                  </span>
                </div>
              </div>

              <span v-if="errors['password']" class="text-danger">{{
                errors["password"][0]
              }}</span>
            </div>

            <div class="form-group">
              <label for=""
                >Merchant Id <span class="text-danger">*</span></label
              >
              <input
                type="email"
                name=""
                class="form-control"
                id=""
                v-model="merchant_id"
                :class="errors['merchant_id'] ? 'border border-danger' : ''"
              />
              <span v-if="errors['merchant_id']" class="text-danger">{{
                errors["merchant_id"][0]
              }}</span>
            </div>
            <div class="form-group">
              <label for=""
                >Payment Url <span class="text-danger">*</span></label
              >
              <input
                type="email"
                name=""
                class="form-control"
                id=""
                v-model="payment_url"
                :class="errors['payment_url'] ? 'border border-danger' : ''"
              />
              <span v-if="errors['payment_url']" class="text-danger">{{
                errors["payment_url"][0]
              }}</span>
            </div>
            <div class="form-group">
              <label for="">Token Url <span class="text-danger">*</span></label>
              <input
                type="email"
                name=""
                class="form-control"
                id=""
                v-model="payment_token_url"
                :class="
                  errors['payment_token_url'] ? 'border border-danger' : ''
                "
              />
              <span v-if="errors['payment_token_url']" class="text-danger">{{
                errors["payment_token_url"][0]
              }}</span>
            </div>
          </form>
          <form class="form" v-else>
            <div class="form-group">
              <label for=""
                >Public Key <span class="text-danger">*</span></label
              >
              <input
                type="email"
                name=""
                class="form-control"
                id=""
                v-model="public_key"
                :class="errors['public_key'] ? 'border border-danger' : ''"
              />
              <span v-if="errors['public_key']" class="text-danger">{{
                errors["public_key"][0]
              }}</span>
            </div>

            <div class="form-group mb-1">
              <label for=""
                >Secret Api Key <span class="text-danger">*</span></label
              >
              <div class="input-group">
                <input
                  type="email"
                  name=""
                  class="form-control"
                  id=""
                  v-model="secret_api_key"
                  :class="
                    errors['secret_api_key'] ? 'border border-danger' : ''
                  "
                />
              </div>

              <span v-if="errors['secret_api_key']" class="text-danger">{{
                errors["secret_api_key"][0]
              }}</span>
            </div>

            <div class="form-group">
              <label for=""
                >Developer Id <span class="text-danger">*</span></label
              >
              <input
                type="email"
                name=""
                class="form-control"
                id=""
                v-model="developer_id"
                :class="errors['developer_id'] ? 'border border-danger' : ''"
              />
              <span v-if="errors['developer_id']" class="text-danger">{{
                errors["developer_id"][0]
              }}</span>
            </div>
            <div class="form-group">
              <label for=""
                >Version Number <span class="text-danger">*</span></label
              >
              <input
                type="email"
                name=""
                class="form-control"
                id=""
                v-model="version_number"
                :class="errors['version_number'] ? 'border border-danger' : ''"
              />
              <span v-if="errors['version_number']" class="text-danger">{{
                errors["version_number"][0]
              }}</span>
            </div>
            <div class="form-group">
              <label for=""
                >Service Url <span class="text-danger">*</span></label
              >
              <input
                type="email"
                name=""
                class="form-control"
                id=""
                v-model="service_url"
                :class="errors['service_url'] ? 'border border-danger' : ''"
              />
              <span v-if="errors['service_url']" class="text-danger">{{
                errors["service_url"][0]
              }}</span>
            </div>
          </form>
        </div>
        <div
          class="card-footer"
          v-if="currentUser.c.payment_method != 'heartland'"
        >
          <button
            class="btn btn-success"
            style="float: right"
            ref="kt_save_payment_setting"
            @click="onSubmit()"
          >
            <i class="fa fa-check"></i>Save
          </button>
        </div>
        <div class="card-footer" v-else>
          <button
            class="btn btn-success"
            style="float: right"
            ref="kt_save_heart_payment_setting"
            @click="onHeartSubmit()"
          >
            <i class="fa fa-check"></i>Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import {
  FETCH_PAYMENT_SETTING,
  UPDATE_PAYMENT_SETTING,
  FETCH_HEART_PAYMENT_SETTING,
  UPDATE_HEART_PAYMENT_SETTING
} from "@/core/services/store/actions.type";

export default {
  data() {
    return {
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Setting",
          route: ""
        },
        {
          id: 3,
          title: "Payment Method Setting",
          route: ""
        }
      ],

      errors: [],

      id: 0,
      username: "",
      password: "",
      merchant_id: "",

      password_type: "password",
      payment_url: "",
      payment_token_url: "",
      heart_id: 0,
      public_key: "",
      secret_api_key: "",
      developer_id: "",
      version_number: "",
      service_url: ""
    };
  },

  computed: {
    ...mapGetters(["currentUser"])
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    if (this.currentUser.c.payment_method != "heartland") {
      this.$store.dispatch(FETCH_PAYMENT_SETTING).then(data => {
        if (data.status == true) {
          this.id = data.data.id;
          this.username = data.data.username;
          this.password = data.data.password;
          this.merchant_id = data.data.merchant_id;
          this.payment_url = data.data.payment_url;
          this.payment_token_url = data.data.payment_token_url;
        }
      });
    } else {
      this.$store.dispatch(FETCH_HEART_PAYMENT_SETTING).then(data => {
        if (data.status == true) {
          this.heart_id = data.data.id;
          this.public_key = data.data.public_key;
          this.secret_api_key = data.data.secret_api_key;
          this.developer_id = data.data.developer_id;
          this.version_number = data.data.version_number;
          this.service_url = data.data.service_url;
        }
      });
    }
  },
  methods: {
    showPassword() {
      if (this.password_type === "password") {
        this.password_type = "text";
      } else {
        this.password_type = "password";
      }
    },

    onSubmit() {
      this.errors = [];

      const submitButton = this.$refs["kt_save_payment_setting"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      var data_ = {
        id_: this.id,
        merchant_id: this.merchant_id,
        username: this.username,
        password: this.password,
        payment_url: this.payment_url,
        payment_token_url: this.payment_token_url
      };
      // this.loadingButton(ref);
      this.$store
        .dispatch(UPDATE_PAYMENT_SETTING, data_)
        .then(data => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          if (this.id == 0) {
            this.id = data.id;
          }
          this.$toastr.s(data.msg);
        })
        .catch(err => {
          // var eMessaga = err.error;
          // this.$toastr.e(eMessaga);
          this.errors = err.error;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        });
    },
    onHeartSubmit() {
      this.errors = [];

      const submitButton = this.$refs["kt_save_heart_payment_setting"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      var data_ = {
        id_: this.heart_id,
        public_key: this.public_key,
        secret_api_key: this.secret_api_key,
        developer_id: this.developer_id,
        version_number: this.version_number,
        service_url: this.service_url
      };
      // this.loadingButton(ref);
      this.$store
        .dispatch(UPDATE_HEART_PAYMENT_SETTING, data_)
        .then(data => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.heart_id = data.id;

          this.$toastr.s(data.msg);
        })
        .catch(err => {
          // var eMessaga = err.error;
          // this.$toastr.e(eMessaga);
          this.errors = err.error;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
        });
    }
  }
};
</script>

<style></style>
